import React, { useEffect, useRef } from 'react';
import './system.scss';
import ContactForm from '../form/form';
import { useTranslation } from 'react-i18next';



function System() {
  const { t } = useTranslation();


  return (
    <div className="system">
      <div className="header-image">
        <h1 className = "system-heading">{t('system_page_title')}</h1>
        <img src="images/techField.webp" alt="Header" />
      </div>
      <div className="system-container">
        <div className="row system-row-1">
            <div className="col-md-6 ">
                <img className = "iii" src="/images/Banner_1.webp" alt="system" />
            </div>
            <div className="col-md-6 system-text1">
                <h2 className = "system-headingText">{t('system_page_content_1_title')}</h2>
                <p className = "system-bodyText">{t('system_page_content_1_text')}</p>
                <ul className = "system-list">
                  <li>{t('system_page_list_1_item_1')}</li>
                  <li>{t('system_page_list_1_item_2')}</li>
                  <li>{t('system_page_list_1_item_3')}</li>
                  <li>{t('system_page_list_1_item_4')}</li>
                  <li>{t('system_page_list_1_item_5')}</li>  
                </ul>
            </div>
        </div>

        <div className="row system-row-2">
            <div className="col-md-6 system-text2">
            <h2 className = "system-headingText">{t('system_page_content_2_title')}</h2>
                <p className = "system-bodyText">
                  {t('system_page_content_2_text_1')}
                  <br/>
                  <br/>
                  {t('system_page_content_2_text_2')}
                  <br/>
                  <br/>
                  {t('system_page_content_2_text_3')}
                </p>
                <button className = "system-button"><a target='_blank' href='https://allentysistemadeinventario.com.br/inventario/login.aspx'>{t('system_page_button')}</a></button>
            </div>
            <div className="col-md-6">
              <img className = "iii" src="/images/Banner_2.webp" alt="system" />
            </div>
        </div>
    </div>
    <ContactForm/>
    </div>
  );
}

export default System;