import React, { useEffect, useState, useRef } from 'react';
import './system-cards.scss';  // Import custom SCSS for card styling
import { useTranslation } from 'react-i18next';

function SystemCards() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

  }, []);



  return (
    <div className="container my-4">
      <h2 className="text-center my-4">
        {t('system_card_heading')} 
      </h2> {/* Centralized Heading */}

      <div className="row">
        <div className="col-md-4">
          <div className="card custom-card">
            <img src="/images/cloud_ilustration.webp" className="card-img-top" alt="Card 1" />
            <div className="card-body">
              <h5 className="card-title">{t('system_card_title_1')}</h5>
              <p className="card-text">{t('system_card_content_1')}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card custom-card">
            <img src="/images/android_ilustration.webp" className="card-img-top" alt="Card 2" />
            <div className="card-body">
              <h5 className="card-title">{t('system_card_title_2')}</h5>
              <p className="card-text">{t('system_card_content_2')}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card custom-card">
            <img src="/images/anywhere.ilustration.webp" className="card-img-top" alt="Card 3" />
            <div className="card-body">
              <h5 className="card-title">{t('system_card_title_3')}</h5>
              <p className="card-text">{t('system_card_content_3')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center my-4">
        <a href={t('routes.system')}><button className="btn btn-primary custom-button">{t('learn_more')}</button></a>
      </div>
    </div>
  );
}

export default SystemCards;