import React from 'react';
import CarouselComponent from './home-carousel/home-carousel';
import SystemCards from './system-cards/system-cards';
import AboutUs from './aboutUs/aboutUsPage';
import Clients from './clients/clients';
import MapWithInfo from './map/map';
import ContactForm from '../form/form';


const Home = () => {
  return (
   <>    
    <CarouselComponent/>
    <AboutUs/>
    <SystemCards/>
    <Clients/>
    <MapWithInfo/>
    <ContactForm/>
   </>
  );
};

export default Home;