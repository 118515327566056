import React, { useState, useCallback, useEffect } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import './map.scss';
import { useTranslation } from 'react-i18next';

// URL to the TopoJSON data for the world map
const geoUrl = '/images/ne_50m_admin_0_countries.geojson';

// Example list of countries where the company has a presence and their descriptions
const companyPresence = [
  {
    country: 'Canada',
    phoneNumber: '+1 (368) 299-0835',
    personName: 'Claudio Mulero De Toledo',
    email: 'claudio.toledo@allenty.com',
  },
  {
    country: 'Brazil',
    phoneNumber: '+1 (368) 299-0835',
    personName: 'Claudio Mulero De Toledo',
    email: 'claudio.toledo@allenty.com',
  },
  {
    country: 'Peru',
    phoneNumber: '+51 981 265 881',
    personName: 'Everaldo Macedo',
    email: 'everaldo.macedo@inventorylatam.com',
  },
  {
    country: 'Mexico',
    phoneNumber: '+52 55 1234 5678',
    personName: 'David Sacal',
    email: 'd.sacal@inventarios.com.mx',
  },
  {
    country: 'Chile',
    phoneNumber: '+51 981 265 881',
    personName: 'Everaldo Macedo',
    email: 'everaldo.macedo@inventorylatam.com',
  },
];

const MemoizedGeography = React.memo(({ geo, isPresent, handleCountryClick }) => (
  <Geography
    key={geo.rsmKey}
    geography={geo}
    fill={isPresent ? '#0056b3' : '#EAEAEC'}
    stroke="#D6D6DA"
    onClick={() => handleCountryClick(geo.properties.NAME)}
    style={{
      default: { outline: 'none', cursor: isPresent ? 'pointer' : 'default' },
      hover: isPresent ? { outline: 'none', fill: '#007bff' } : { outline: 'none' },
      pressed: { outline: 'none' },
    }}
  />
));

function MapWithInfo() {
  const { t } = useTranslation();
  
  // State to store the selected country and the corresponding information
  const [selectedCountryInfo, setSelectedCountryInfo] = useState(null);

  // Click handler to set the selected country information
  const handleCountryClick = useCallback((countryName) => {
    const countryInfo = companyPresence.find((country) => country.country === countryName);
    if (countryInfo) {
      setSelectedCountryInfo(countryInfo);
    }
  }, []);

  // Set default selected country to Brazil
  useEffect(() => {
    const defaultCountry = companyPresence.find((country) => country.country === 'Brazil');
    if (defaultCountry) {
      setSelectedCountryInfo(defaultCountry);
    }
  }, []);

  return (
    <div className="map-container">
      <h2 className="map-heading">{t('map_heading')}</h2>
      <p className="map-subheading">{t('map_description')}</p>
      <hr className="map-division"></hr>
      <div className="map-info-container">
        <div className="map-section">
          <ComposableMap projection="geoMercator" className="world-map">
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const isPresent = companyPresence.some((country) => country.country === geo.properties.NAME);
                  return (
                    <MemoizedGeography
                      key={geo.rsmKey}
                      geo={geo}
                      isPresent={isPresent}
                      handleCountryClick={handleCountryClick}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        </div>
        {selectedCountryInfo && (
          <div className="info-section">
            <div className="contact-card fade-in-text">
              <div className="card-body">
                <h2 className="card-title">{selectedCountryInfo.country}</h2>
                <p className="card-text"><strong>{t('map_name')}:</strong> {selectedCountryInfo.personName}</p>
                <p className="card-text"><strong>{t('map_phone')}:</strong> {selectedCountryInfo.phoneNumber}</p>
                <p className="card-text"><strong>Email:</strong> {selectedCountryInfo.email}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MapWithInfo;