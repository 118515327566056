import React, { useEffect } from 'react';
import './inventory.scss';
import ContactForm from '../form/form';
import { useTranslation } from 'react-i18next';

function Inventory() {

  const { t } = useTranslation();

  return (
    <div className="inventory-container">
      <div className="header-image">
        <h1 className="inventory-heading">{t('inventory_page_title')}</h1>
        <p className="inventory-subheading">{t('inventory_page_subtitle')}</p>
        <img src="/images/factory3.webp" alt="Inventário" />
      </div>
      <div className="inventory-content">
            <div className="row inventory-row-1">
            <div className="col-md-6">
                <img className="first-row-image" src="/images/undraw_engineering-team_13ax.svg" alt="inventory" />
            </div>
            <div className="col-md-6 inventory-text1">
                <h2 className="inventory-headingText">{t('inventory_page_content_1_title')}</h2>
                <p className="inventory-bodyText">
                  {t('inventory_page_content_1_text')}
                </p>
                <ul className = "inventory-list">
                  <li>{t('inventory_page_list_1_item_1')}</li>
                  <li>{t('inventory_page_list_1_item_2')}</li>
                  <li>{t('inventory_page_list_1_item_3')}</li>
                  <li>{t('inventory_page_list_1_item_4')}</li>
                  <li>{t('inventory_page_list_1_item_5')}</li>  
                </ul>
            </div>
            </div>
            <div className="row inventory-middle-row  text-black p-4">
              <div className="col-12 text-center">
                <h2 className="mb-3">{t('inventory_page_middle_title')}</h2>
                <p className="lead">
                  {t('inventory_page_middle_text')}
                </p>
              </div>
            </div>
            <div className="row inventory-row-2">
                <div className="col-md-6 inventory-text2">
                    <h2 className="inventory-headingText">{t('inventory_page_content_2_title')}</h2>
                    <p className="inventory-bodyText">
                      {t('inventory_page_content_2_text_1')}
                      <br/>
                      <br/>
                      {t('inventory_page_content_2_text_2')} <br/> {t('inventory_page_content_2_text_3')}                  
                    </p>
                </div>
                <div className="col-md-6">
                    <img className="second-row-image" src="/images/undraw_growth-curve_kzjb.svg" alt="inventory" />
                </div>
            </div>
        </div>
        <ContactForm />
    </div>
  );
}

export default Inventory;