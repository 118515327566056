import React from 'react';
import './footer.scss';  // Import custom SCSS for styling
import { useTranslation } from 'react-i18next';

function Footer() {
  const currentYear = new Date().getFullYear();
  const { t, i18n } = useTranslation();

  return (
    <footer className="footer">
      <p>© {currentYear} Allenty. {t('footer')} <a href='https://www.linkedin.com/in/pedro-henrique-gomes-de-toledo/'>Pedro Gomes</a></p>
    </footer>
  );
}

export default Footer;