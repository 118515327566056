import React, { useEffect } from 'react';
import ContactForm from '../form/form';
import './impairment.scss';
import { useTranslation } from 'react-i18next';

function Impairment() {
  const { t } = useTranslation();

    return (

        <section className='impairment'>
            <div className="header-image">
                <h1 className = "impairment-heading">
                    {t('impairment_page_title')}
                </h1>
                <img src="images/notebook.webp" alt="Header" />
            </div>
            <div className="impairment-container">
                <div className="row impairment-row-1">
                    <div className="col-md-6 ">
                        <img className = "iii" src="/images/scale.webp" alt="impairment" />
                    </div>
                    <div className="col-md-6 impairment-text1">
                        <h2 className = "impairment-headingText">
                            {t('impairment_page_content_1_title')}
                        </h2>
                        <p className = "impairment-bodyText">
                            {t('impairment_page_content_1_content_1')}
                        </p>
                        <p className = "impairment-bodyText">
                            {t('impairment_page_content_1_content_2')}
                        </p>
                        <ul className = "impairment-list">
                            <li>
                                <strong>
                                    {t('impairment_page_list_1_strong_1')}
                                </strong>
                                {t('impairment_page_list_1_1')}
                            </li>
                            <li>
                                <strong>
                                    {t('impairment_page_list_1_strong_2')}
                                </strong>
                                {t('impairment_page_list_1_2')}
                            </li>
                            <li>
                                <strong>
                                    {t('impairment_page_list_1_strong_3')}
                                </strong>
                                {t('impairment_page_list_1_3')}
                            </li>
                        </ul>

                    </div>
                </div>
                <div className="row impairment-row-2">
                    <div className="col-md-6 impairment-text2">
                        <h2 className = "impairment-headingText">
                        {t('impairment_page_content_2_title')}
                        </h2>
                        <ul className = "impairment-list">
                            <li>{t('impairment_page_list_2_1')}</li>
                            <li>{t('impairment_page_list_2_2')}</li>
                            <li>{t('impairment_page_list_2_3')}</li>
                        </ul>
                        <p className = "impairment-bodyText">
                            {t('impairment_page_content_2_content')}
                        </p>
                    </div>
                    <div className="col-md-6">
                    <img className = "iii" src="/images/analysis.webp" alt="impairment" />
                    </div>
                </div>
            </div>
            <ContactForm/>
        </section>
    );
        
}

export default Impairment;