import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Routes, Route,Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navibar from './components/navbar/navbar';
import Home from './components/home/home';
import System from './components/system/system';
import Inventory from './components/inventory/inventory';
import Cases from './components/cases/cases';
import Impairment from './components/impairment/impairment';
import WhatsAppWidget from './components/whatsapp/whatsapp';
import './App.css';
import Footer from './components/footer/footer';
import NotFound from './components/404/404';


function App() {

  const { t, i18n } = useTranslation();


  return (
    <>
    <Navibar/>

    <WhatsAppWidget/>

    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path= {`${t('routes.system')}`} element={<System/>}/>
        <Route path={`${t('routes.inventory')}`} element={<Inventory/>}/>
        <Route path={`${t('routes.cases')}`} element={<Cases/>}/>
        <Route path="*" element={<NotFound />} /> {/* Add the 404 route */}
        <Route path={`impairment`} element={<Impairment/>}/>
      </Routes>
    </Router>
    <Footer/>
    </>
  );
}

export default App;
