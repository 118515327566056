import React, { useState } from 'react';
import './cases.scss';
import ContactForm from '../form/form';
import { useTranslation } from 'react-i18next';


function Cases() {

  const { t } = useTranslation();

  const [selectedCase, setSelectedCase] = useState({
    title: t('cases_title_1'),
    Scenario: t('cases_scenario_1'),
    Project: t('cases_project_1'),
    image: '/images/Brasil_Mexico.webp'
  });

  const caseStudies = [
    {
      title: t('cases_title_1'),
      Scenario: t('cases_scenario_1'),
      Project: t('cases_project_1'),
      image: '/images/Brasil_Mexico.webp'
    },
    {
      title: t('cases_title_2'),
      Scenario: t('cases_scenario_2'),
      Project: t('cases_project_2'),
      image: '/images/img1-case.webp'
    },
    {
      title: t('cases_title_3'),
      Scenario: t('cases_scenario_3'),
      Project: t('cases_project_3'),
      image: '/images/img2-case.jpg'
    },
    {
      title: t('cases_title_4'),
      Scenario: t('cases_scenario_4'),
      Project: t('cases_project_4'),
      image: '/images/img3-case.webp'
    },
    {
      title: t('cases_title_5'),
      Scenario: t('cases_scenario_5'),
      Project: t('cases_project_5'),
      image: '/images/img4-cases.webp'
    }
  ];

  return (
    <>
    <div className="cases-container container">
      <div className="row">
        <div className="col-md-4">
          <div className="accordion" id="caseAccordion">
            {caseStudies.map((caseStudy, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`heading${index}`}>
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                    onClick={() => setSelectedCase(caseStudy)}
                  >
                    {caseStudy.title}
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#caseAccordion"
                >
                  <div className="accordion-body">
                    {caseStudy.Scenario}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-8">
          <div className="case-content">
            <div className="row">
              <div className="col-md-6">
                <h2>{selectedCase.title}</h2>
                <p>{selectedCase.Project}</p>
              </div>
              <div className="col-md-6">
                <img src={selectedCase.image} alt={selectedCase.title} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <ContactForm />
    </>
  );
}

export default Cases;