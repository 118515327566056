import React from 'react';
import './clients.scss';  // Import custom SCSS for styling

function Clients() {
  const clientLogos = [
    '/images/logo1.webp',
    '/images/logo2.webp',
    '/images/logo3.webp',
    '/images/logo4.webp',
    '/images/logo5.webp',
    '/images/logo6.webp',
    '/images/logo7.webp',
    '/images/logo8.webp'
  ];

  return (
    <div className="clients-container">
      <div className="logos-slider">
        {clientLogos.map((logo, index) => (
          <div className="logo-item" key={index}>
            <img src={logo} className="client-logo" alt={`Client ${index + 1}`} />
          </div>
        ))}
        {clientLogos.map((logo, index) => (
          <div className="logo-item" key={index + clientLogos.length}>
            <img src={logo} className="client-logo" alt={`Client ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Clients;