import React from 'react';
import './home-carousel.scss';  // Import custom SCSS styles
import { useTranslation } from 'react-i18next';


function CarouselComponent() {

  const { t } = useTranslation();


  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="ride" > {/* Set interval to 6000ms (6 seconds) */}
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
      </div>
      
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src="/images/werehouse.webp" className="d-block w-100" alt="Slide 1" />
          <div className="carousel-caption left-text-container">
            <div className="caption-content">
              <h2>{t('carousel_title_1_1')}</h2>
              <h1>{t('carousel_title_1_2')}</h1>
              <p>{t('carousel_content_1')}</p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src="/images/factory.webp" className="d-block w-100" alt="Slide 2" />
          <div className="carousel-caption left-text-container">
            <div className="caption-content">
              <h2>{t('carousel_title_2_1')}</h2>
              <h1>{t('carousel_title_2_2')}</h1>
              <p>{t('carousel_content_1')}</p>
            </div>
          </div>
        </div>
      </div>
      
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
      
    </div>
  );
}

export default CarouselComponent;